<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->
    <v-card class="mb-10" elevation="0" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4 v-if="$store.state.user_menu_dashboard.is_admin != 1">الأضافات</h4>
        <v-spacer></v-spacer>
        <v-btn
          v-if="this.$store.state.user_menu_dashboard.is_admin != 1"
          to="/addition"
          color="primary"
          >اضافة جديد</v-btn
        >
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="products"
        :headers="headers"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.add_name`]="{ item }">
          {{ item.add_name[0].name }}
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <img
            v-if="item.image"
            width="60"
            :src="`${$store.state.def_path}/${item.image}`"
          />
          <span v-else>لايوجد</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div>
            <v-chip
              class="px-2"
              x-small
              v-if="item.status == 1"
              color="success"
            >
              متوفر</v-chip
            >

            <v-chip
              class="px-2"
              x-small
              v-else-if="item.status == 2"
              color="error"
            >
              غير متوفر</v-chip
            >

            <v-chip class="px-2" x-small v-else color="grey lighten-2">
              مخفي</v-chip
            >
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="$store.state.user_menu_dashboard.is_admin != 1"
            :to="{name:'addition', params:{id: item.id} }"
            small
            icon
          >
            <v-icon small>edit</v-icon>
          </v-btn>
          <v-btn
            v-if="$store.state.user_menu_dashboard.is_admin != 1"
            @click="open_delete_dialog(item.id)"
            small
            icon
          >
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف الأضافة ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_product()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف الآضافة</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "notes",

  data() {
    return {
      delete_id: null,
      delete_loader: false,
      snackbar_success: false,
      delete_dialog: false,
      data_loaded: false,
      products: [],
      headers: [
        {
          value: "add_name",
          text: "الاسم",
          align: "center",
        },

        {
          value: "price",
          text: "سعر",
          align: "center",
        },
        {
          value: "status",
          text: "الحالة",
          align: "center",
        },
        {
          value: "actions",
          text: "أجراءات",
          align: "center",
        },
      ],
    };
  },
  methods: {
    get_additions() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "additions",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.products = Object.assign([], res.data.data);
        });
    },
    open_delete_dialog(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },
    delete_product() {
      if (!this.delete_loader) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `additions/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            let note_index = this.products.findIndex(
              (e) => e.id == this.delete_id
            );

            this.products.splice(note_index, 1);
            this.delete_loader = false;
            this.delete_dialog = false;
            this.snackbar_success = true;
          });
      }
    },
    change_status(item) {
      this.$store.dispatch("public__request", {
        config: {
          url: "Status",
          method: "post",
        },
        data: {
          product_id: item.id,
          status: item.status ? 1 : 0,
        },
      });
    },
  },
  mounted() {
    if (this.$store.state.user_menu_dashboard.is_admin == 1) {
      if (!this.$route.query.store) {
        this.$router.push("/Stores");
      }
    }
    this.get_additions();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--switch {
    .v-input__slot {
      justify-content: center;
    }
  }
}
</style>
